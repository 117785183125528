import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["col col-12", [
                _ctx.xxs && "col-xxs-" + _ctx.xxs,
                _ctx.xs && "col-xs-" + _ctx.xs,
                _ctx.sm && "col-sm-" + _ctx.sm,
                _ctx.md && "col-md-" + _ctx.md,
                _ctx.lg && "col-lg-" + _ctx.lg,
                _ctx.xl && "col-xl-" + _ctx.xl,
                _ctx.xxl && "col-xxl-" + _ctx.xxl,
            ]])
    }, [
        _renderSlot(_ctx.$slots, "default")
    ], 2));
}
