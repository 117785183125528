import { ref, watch } from "vue";
import { Field, ErrorMessage } from "vee-validate";
export default {
    name: "BaseTextArea",
    inheritAttrs: false,
    components: {
        Field: Field,
        ErrorMessage: ErrorMessage,
    },
    props: {
        name: String,
        modelValue: {
            type: String,
        },
        rows: {
            type: Number,
            default: 4,
        },
        placeholder: {
            type: String,
            default: "",
        },
    },
    setup: function (props) {
        var modell = ref("");
        watch(function () { return props.modelValue; }, function (val) {
            modell.value = val !== null && val !== void 0 ? val : "";
        });
        return {
            modell: modell,
        };
    },
};
