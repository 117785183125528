import { defineComponent } from "vue";
export default defineComponent({
    name: "BaseCol",
    props: {
        xxs: {
            type: String,
            required: false,
        },
        xs: {
            type: String,
            required: false,
        },
        sm: {
            type: String,
            required: false,
        },
        md: {
            type: String,
            required: false,
        },
        lg: {
            type: String,
            required: false,
        },
        xl: {
            type: String,
            required: false,
        },
        xxl: {
            type: String,
            required: false,
        },
    },
});
